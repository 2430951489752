
.slick-slider .slick-slide {
  padding-right: 15px !important;
  padding-top: 20px;
  padding-bottom: 10px;
}
body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
body::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 10px;
  border: none;
}
.css-14u9vth-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
  font-size: 15px !important;
  margin: 0 !important;
  height: 50px !important;
}
.css-1lazfum-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.css-wtw5vo-MuiInputBase-input-MuiOutlinedInput-input {
  /* height: 13px !important; */
}
.css-17jfbmq-MuiFormLabel-root-MuiInputLabel-root {
  /* top: -3px !important; */
}
.css-1o2jng6-MuiOutlinedInput-notchedOutline {
  /* top: -3px !important; */
}
.css-wgcozo-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.css-1kpdewa-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  /* padding: 2.5px 4px 7.5px 6px !important; */
}
.css-ee7kim-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.css-k2nmee-MuiList-root + .css-k2nmee-MuiList-root {
  margin-top: 0 !important;
}

.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  border: none;
  height: 40px !important;
  font: inherit !important;
  height: 1.4375em !important;
  letter-spacing: inherit !important;
  display: flex !important;
}

/* CSS variables. */
:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInputInput:focus-visible {
  flex: 1;
  min-width: 0;
  border: none;
  outline: none;
}

.PhoneInputCountryIcon {
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);

  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  display: block;

  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.css-54j8as-MuiListItem-root {
  overflow: hidden !important;
  white-space: nowrap !important;
}
.MuiListItem-root .MuiButton-startIcon svg path{
  fill: #ffff ;
}
.MuiListItem-root .active .MuiBox-root{
  color: #ffff;
}

/* .MuiTypography-body2{
  font-size: 18px !important;
  color:#0A2540;
  font-weight: 700 !important;
} */

.css-6n8s64-MuiSvgIcon-root-MuiStepIcon-root.Mui-active circle {
  fill: linear-gradient(100deg, #FF5733 0.25%, #FD4C26 46.18%, #FA8D76 97.15%);
}
.css-8t49rw-MuiStepConnector-line,
.MuiStepContent-root{
  border-left: 1px solid #f0f0f1 !important;
}

.MuiStepLabel-vertical{
  padding: unset !important;
}


.field_error fieldset{
border-width: 0px !important;
}
.field_error .MuiOutlinedInput-input{
  padding: 12px 14px;
  padding-right: 15px !important;
}
.field_error .MuiFormHelperText-contained{
  padding-top:5px !important;
}
.field_error .MuiInputBase-adornedEnd{
  padding: 2px 7px !important;
}
.field_error1 .MuiInputBase-adornedEnd{
  padding: 4.5px 7px !important;
}

.field_error .MuiInputBase-adornedStart{
  padding: 2px 7px !important;
}
.PhoneInputInput{
  background: #FBFBFB;
}

.scroller::-webkit-scrollbar{
  display: none !important;
}
/* .MuiSvgIcon-fontSizeMedium{
  color: #86909F;
} */

.button_class:hover .svg-class-name path{
  fill: #FFF !important; /* Change to white on hover */
}
.collaps-menu:hover .svg-class-name11 path{
  fill: #F95734 !important;
}
.svg-class-name11 path{
  fill: #6D7482 !important;
}
.svg-class-name12 path{
  fill: #F95734 !important;
}
.svg-class{
  height: 18px !important;
}


.Select-box .css-1o2jng6-MuiOutlinedInput-notchedOutline{
  border-color: #EFEFF0 !important;
  border-width: 1px !important;
}
.scroll-container {
  scroll-behavior: smooth;
}

.image-name span{
width: 200px !important;
overflow: hidden !important;
text-overflow: ellipsis !important;
}
.image-name1 span{
  width: 150px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  font-size: 12px;

}
.my-auto .MuiSvgIcon-fontSizeSmall{
  display: none !important;
}

.svg-current-color, .svg-current-color path {
  fill: currentColor
}
:root {
  --navy-blue-color: "#0e0e0e";
  --secondary-grey-color: "#757575";
}

.calendar-custom-wrapper {
  position: absolute;
  /* top: 48px !important; */
  z-index: 9999999 !important;
  /* height: 480px !important; */
  background: #fff;
}

.dashboard-date-picker .react-calendar {
  max-width: 320px;
  background: var(--navy-blue-color);
  box-shadow: 3px 4px 69px rgb(0 0 0 / 50%);
  border: none;
  border-radius: 8px;
  font-family: "Inter, sans-serif";
  padding: 28px 22px;
  width: auto;
}

.dashboard-date-picker .react-calendar {
  position: relative;
}

.dashboard-date-picker .react-calendar__navigation button {
  color: #ff5733;
  font-family: "Inter";
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  background: none;
  border: 0;
}
.dashboard-date-picker .react-calendar__navigation__label {
  color: #0a2540 !important;
}

.dashboard-date-picker .react-calendar__navigation button:disabled {
  background: var(--navy-blue-color);
}

.dashboard-date-picker .react-calendar__navigation button:first-child,
.dashboard-date-picker .react-calendar__navigation button:last-child {
  display: none;
}

.dashboard-date-picker .search-calendar-btn {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 24px;
  bottom: 24px;
}

.dashboard-date-picker .search-calendar-btn::before {
  background-color: var(--navy-blue-color);
}

.dashboard-date-picker .react-calendar__navigation button:last-child::before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border-radius: 4px;
  background-color: var(--navy-blue-color);
  z-index: -1;
}

.dashboard-date-picker .react-calendar__navigation button:last-child::after {
  content: "";
  font-size: 16px;
  background: linear-gradient(to left, #00ffa3, #000) !important;
  -webkit-background-clip: text;
  color: transparent;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(3) {
  flex-grow: inherit !important;
  pointer-events: none !important;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(2),
.dashboard-date-picker .react-calendar__navigation button:nth-child(4) {
  padding: 0px;
  width: 24px;
  border-radius: 50%;
  min-width: unset;
  position: absolute;
  top: 40px;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(2) {
  right: 48px;
  padding-right: 2px;
  top: 30px;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(4) {
  right: 22px;
  padding-left: 2px;
  top: 30px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

.react-calendar__navigation button:enabled:hover {
  background-color: #ff5733 !important;
  color: white;
}

.dashboard-date-picker .react-calendar__month-view__weekdays {
  color: var(--secondary-grey-color);
  font-family: "Inter, sans-serif";
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: capitalize;
  margin-top: 32px !important;
}

.dashboard-date-picker .react-calendar__month-view__weekdays abbr {
  text-decoration: none;
}

.dashboard-date-picker .react-calendar__tile {
  border: none;
  background: none;
  color: var(--secondary-grey-color);
  font-family: "Inter, sans-serif";
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  padding: 8px;
  border-radius: 50%;
  margin-top: 8px;
  cursor: pointer;
  /* margin-left: 4px;
    margin-right: 4px; */
}

.dashboard-date-picker .react-calendar__tile:disabled {
  background: var(--navy-blue-color);
  border-radius: 50%;
}

.dashboard-date-picker .react-calendar__tile--rangeStart,
.dashboard-date-picker .react-calendar__tile--rangeEnd,
.dashboard-date-picker .react-calendar__tile--hasActive,
.dashboard-date-picker .react-calendar__tile:enabled:hover,
.dashboard-date-picker .react-calendar__tile:enabled:focus {
  /* background: var(--secondary-neon-color) !important; */
  background-color: #ff5733 !important;
  border-radius: 50%;
  color: white !important;
}

.dashboard-date-picker .react-calendar__tile--active,
.dashboard-date-picker
  .react-calendar--selectRange
  .react-calendar__tile--hover {
  background: linear-gradient(
    100deg,
    #ff5733 0.25%,
    #fd4c26 46.18%,
    #fa8d76 97.15%
  );
  color: white !important;
  border-radius: 50% !important;
}

.dashboard-date-picker .react-calendar__tile--now {
  /* background: transparent; */
  border-radius: 50%;
}
.analytics-calendar-wrapper .calendar-custom-wrapper {
  /* top: 40px !important; */
}

.MuiToolbar-gutters{
  padding-left: 10px !important;
  /* padding-right: 35px !important; */
}

.my-scrollar::-webkit-scrollbar{
  display: none;
}

.my-scrollar::-webkit-scrollbar{
  display: none;
}

html, body {
  overflow: hidden; 
}

.custom-scrollbar::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: gray;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
background: #f1f1f1; 
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
background: #888; 
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
background: #555; 
}

.custom-scrollbar::-webkit-scrollbar-thumb:horizontal {
  background: #A3A2A1;
  border-radius: 10px;
}




iframe {
  display: none;
}